
    import { Component, Vue, Watch, Prop } from "vue-property-decorator"
    import { mapGetters, mapMutations } from 'vuex'
    import { Caxios } from './../../../../utils/axios'
    import store from "@/store";
    @Component({
        name: "CourseTest",
        computed: {
            ...mapGetters('common', [ 'branchSchoolId' ]),
            ...mapGetters('course', [ 'subject' ])
        },
        methods: {
            ...mapMutations('course', [ 'setSelectObj', 'setCanSignupFlag' ])
        }
    })
    export default class CourseTest extends Vue {
        @Prop() id!: any
        branchSchoolId!: number
        subject!: Array<any>
        setSelectObj!: any
        setCanSignupFlag!: any
        private testFlag = false
        private selectObj: any = {
            grade: '',
            area: '',
            subject: [],
            restnum: '',
            gradeId:'',
            totalprice: '',
            lessonstatus: ''
        }
        private stableGradesList: Array<any> = []// 接口返回的年级数据
        private stableRestNumsList: Array<any> = []// 接口获取的所有表格数据
        private gradesList: Array<any> = []// 页面使用的年级数据
        private areasList: Array<string> = []// 校区数据
        private subjectsList: Array<any> = []// 学科数据

        private datesList1: Array<string> = []// 表格日期数据1
        private daysList1: Array<string> = []// 表格星期1
        private restNumsList1: Array<any> = []// 剩余名额表格数据1

        private datesList2: Array<string> = []// 表格日期数据2
        private daysList2: Array<string> = []// 表格星期2
        private restNumsList2: Array<any> = []// 剩余名额表格数据2

        private restnumActive: string = ''// 剩余名额
        private curBranchSchoolId: number = -1// 当前校区

        private periods: Array<any> = []

        @Watch('branchSchoolId', { immediate: true, deep: true})
        branchSchoolIdWatch(newVal: number): void {
            this.resetAllData()
            if(newVal > 0) {
                this.curBranchSchoolId = newVal
                this.getGradeByBranchschool()
            }
        }
        @Watch('subject', { immediate: true, deep: true})
        subjectWatch(newVal: Array<any>): void {
            if(Array.isArray(newVal) && newVal.length > 0) {
                let _this: any = this
                this.subjectsList = newVal.filter((v: any): boolean => v.id && v.id != '2')
                this.subjectsList.forEach((item: any, index: number): void => {
                    let _temp: any = item
                    _temp.canSelectFlag = true
                    _this.$set(_this.subjectsList, index, _temp)
                })
            }
        }
        @Watch('selectObj', { immediate: true, deep: true })
        selectObjWatch(newVal: any): void {
            let _tempObj: any = {}
            if(this.selectObj.gradeId && this.selectObj.grade && this.selectObj.area && this.subject.length > 0 && this.selectObj.restnum) {
                this.stableRestNumsList.forEach((item: any): void => {
                    if(item.schoolarea == this.selectObj.area && item.grade.indexOf(this.selectObj.gradeId) >= 0 && this.selectObj.restnum == `${item.startdate}${item.period.split('JL')[1]}`) {
                        _tempObj = {
                            grade: this.selectObj.grade,
                            area: this.selectObj.area,
                            subject: this.selectObj.subject,
                            onlyclassid: item.id,
                            gradeId: this.selectObj.gradeId,
                            restnum: this.selectObj.restnum,
                            totalprice: item.totalprice,
                            lessonstatus: item.lessonstatus
                        }
                    }
                })
            } else {
                _tempObj = {
                    grade: this.selectObj.grade,
                    area: this.selectObj.area,
                    subject: this.selectObj.subject,
                    onlyclassid: '',
                    gradeId: this.selectObj.gradeId,
                    restnum: this.selectObj.restnum,
                    totalprice: this.selectObj.totalprice,
                    lessonstatus: this.selectObj.lessonstatus
                }
            }
            this.setSelectObj(_tempObj)
        }
        created() {
          this.getGrade()
        }
        destroyed() {
          this.getGrade2()
        }
        private resetAllData (): void {
            this.datesList1 = []// 表格日期数据1
            this.daysList1 = []// 表格星期1
            this.restNumsList1 = []// 剩余名额表格数据1

            this.datesList2 = []// 表格日期数据2
            this.daysList2 = []// 表格星期2
            this.restNumsList2 = []// 剩余名额表格数据2

            this.restnumActive = ''// 剩余名额

            this.periods = []
            // this.stableGradesList = []
            // this.gradesList = []

            // this.areasList = []
            // this.subjectsList = []
        }
        // 获取年级、班级
        private async getGrade (): Promise<any> {
          let result: any = await Caxios.get({ url: '/api/datadict/multiply/SUBJECT,GRADE,bookTest' })
          store.dispatch('course/setAjaxData', result)
        }
        private async getGrade2 (): Promise<any> {
        let result: any = await Caxios.get({ url: '/api/datadict/multiply/SUBJECT,GRADE' })
        store.dispatch('course/setAjaxData', result)
        }
        // 根据校区获取年级列表
        private async getGradeByBranchschool () {
            
            let _res: any = await Caxios.get({ url: `/api/getGradeByBranchschool/${this.curBranchSchoolId}` });
            if(Array.isArray(_res) && _res.length > 0) {
                this.stableGradesList = _res
                this.getSelectOptionsStable()
            }
        }
        // 获取选项的所有数据
        private async getSelectOptionsStable(grade?: string) {
            console.log(grade, '返回grade')
            const _url: string = grade ? 
                    `/api/official/${this.id}/time?course_id=${this.id}&branch_school_id=${this.curBranchSchoolId}&s_type=time&grade=${grade}` : 
                    `/api/official/${this.id}/time?course_id=${this.id}&branch_school_id=${this.curBranchSchoolId}&s_type=time`,
                _selectOptions: any = await Caxios.get({ url: _url });
            if(_selectOptions && _selectOptions.items && Array.isArray(_selectOptions.items) && _selectOptions.items.length > 0) {
                // if(this.gradesList.length == 0) {
                //     this.formatGradesList()
                // }

                this.formatGradesList()

                if(_selectOptions.changeHeaders && Array.isArray(_selectOptions.changeHeaders) && _selectOptions.changeHeaders.length > 0) {
                    this.formatAboutDList(_selectOptions.changeHeaders)
                }
                this.stableRestNumsList = _selectOptions.items
                if(_selectOptions.areas && Array.isArray(_selectOptions.areas) && _selectOptions.areas.length > 0) {
                    this.areasList = _selectOptions.areas
                }
                if(_selectOptions.periods && Array.isArray(_selectOptions.periods) && _selectOptions.periods.length > 0) {
                    this.periods = _selectOptions.periods
                    this.formatRestnumStart()
                }

                if(
                    (_selectOptions.grade && _selectOptions.grade.length > 0) && 
                    (_selectOptions.changeHeaders && _selectOptions.changeHeaders.length > 0) && 
                    (_selectOptions.items && _selectOptions.items.length > 0) && 
                    (_selectOptions.areas && _selectOptions.areas.length > 0)
                ) {
                    this.setCanSignupFlag(true)
                } else {
                    this.setCanSignupFlag(false)
                }

                // 修改订单的时候使用的
                let fromPerson: any = sessionStorage.getItem('fangtian_person_to_course')
                if(fromPerson) {
                    let _temp: any = JSON.parse(fromPerson)
                    this.selectObj.area = _temp.school_area_name
                    this.selectObj.subject = [ _temp.subject_name ]
                    // sessionStorage.setItem('fangtian_person_to_course_orderid', _temp.orderid)
                }

                this.formatRestnum()
                
                sessionStorage.removeItem('fangtian_person_to_course')
                console.log(_selectOptions, '///times请求是数据')
                this.setStoreSelectObj()
            } else {
                this.stableRestNumsList = []
                this.stableGradesList = []
                this.gradesList = []
                this.areasList = []
                this.subjectsList = []
            }
        }
        // 初始化年级数据
        private formatGradesList (): void {
            this.gradesList = []
            let _res: any = [
                { title: '小学', type: 'xx', list: [] },
                { title: '初中', type: 'cz', list: [] },
                { title: '高中', type: 'gz', list: [] }
            ]
            this.stableGradesList.forEach((item: any, index: number): void => {
                switch(item.type) {
                    case 'xx':
                        _res[0].list.push(item)
                        break
                    case 'cz':
                        _res[1].list.push(item)
                        break
                    case 'gz':
                        _res[2].list.push(item)
                        break
                }
            })
            this.gradesList = _res.filter((v: any): boolean => v.list.length > 0)
        }
        // 初始化日期和星期
        private formatAboutDList (outer: Array<string>): void {
            this.datesList1 = []
            this.daysList1 = []
            this.datesList2 = []
            this.daysList2 = []
            outer.forEach((item: any, index: number): void => {
                let date: string = item.substr(0, 5),
                    day: string = item.substr(5, 2);
                if(index < 7) {
                    this.datesList1.push(date)
                    this.daysList1.push(day)
                } else {
                    this.datesList2.push(date)
                    this.daysList2.push(day)
                }
            })
        }
        // 根据periods 和 datesList1、datesList2分别初始化两个表格的数据
        private formatRestnumStart(): void {
            this.restNumsList1 = []
            this.restNumsList2 = []
            this.periods.forEach((item: string, index: number): void => {
                let _arr: Array<string> = item.replace(')', '').split('JL')[1].split('(')
                this.restNumsList1[index] = [ ..._arr ]
                this.restNumsList2[index] = [ ..._arr ]
                for(let i = 0, len = this.daysList1.length; i < len; i++) {
                    this.restNumsList1[index][i + 2] = {}
                }
                for(let i = 0, len = this.daysList2.length; i < len; i++) {
                    this.restNumsList2[index][i + 2] = {}
                }
            })
        }
        private formatRestnum (): void {
            this.stableRestNumsList.forEach((item: any, index: number): void => {
                let _header = item.header.substr(5, 5)
                // 往表格1里面插入数据
                if(this.datesList1.includes(_header)) {
                    this.formatFullNumber('restNumsList1', item, 1)
                }
                // 往表格2里面插入数据
                if(this.datesList2.includes(_header)) {
                    this.formatFullNumber('restNumsList2', item, 2)
                }
            })
        }

        private formatFullNumber (tableAttr: string, item: any, type: number): void {
            const d: string = item.curlessontdate.replace('月', '-').replace('日', '')
            let _this: any = this,
                _pIndex: number = this.periods.indexOf(item.period),
                _dIndex: number = _this[`datesList${type}`].indexOf(d),  // _this[`daysList${type}`].indexOf(item.week),
                _temp: any = {
                    text: 0,
                    stdnum: 0,
                    hasenter: 0,
                    restnum: ''
                };
            if(
                !this.selectObj.gradeId && !this.selectObj.area || 
                this.selectObj.gradeId && item.grade.indexOf(this.selectObj.gradeId) >= 0 && !this.selectObj.area || 
                !this.selectObj.gradeId && this.selectObj.area && item.schoolarea == this.selectObj.area
            ) {
                _temp.stdnum += item.stdnum
                _temp.hasenter += item.hasenter
                _temp.text = _temp.stdnum - (_temp.hasenter < 0 ? 0 : _temp.hasenter)
                _temp.restnum = `${item.startdate}${item.period.split('JL')[1]}`
                _this.$set(_this[tableAttr][_pIndex], _dIndex + 2, _temp)
            } else {// 全部选择
                if(item.schoolarea == this.selectObj.area && item.grade.indexOf(this.selectObj.gradeId) >= 0) {
                    _temp.stdnum = item.stdnum
                    _temp.hasenter = item.hasenter
                    _temp.text = _temp.stdnum - (_temp.hasenter < 0 ? 0 : _temp.hasenter)
                    _temp.restnum = `${item.startdate}${item.period.split('JL')[1]}`
                    _this.$set(_this[tableAttr][_pIndex], _dIndex + 2, _temp)
                }
            }
        }
        // 根据所选年级，重新初始化学科
        private async formatSubjectsList (grade: string) {
            let _res: any = await Caxios.get({ url: `/api/getSubjectByGrade/${this.curBranchSchoolId}?grade=${grade}` }),
                _this: any = this;
            if(_res && Array.isArray(_res) && _res.length > 0) {
                let _temp: Array<any> = this.subjectsList,
                    _subjectStr: string = '',
                    subArr: Array<any> = []
                _res.forEach((v: any): void => {
                    _subjectStr += `###${v.name}`
                    subArr.push(v.name)
                })
                _temp.map((item: any, index: number): void => {
                    const flag = subArr.find((cell: any) => item.desc.indexOf(cell) > 0)
                    // if(_subjectStr.indexOf(item.desc) < 0) {
                  if(!flag) {
                        item.canSelectFlag = false
                    } else {
                        item.canSelectFlag = true
                    }
                    _this.$set(_this.subjectsList, index, item)
                })
            }
        }
        // 选择年级操作
        private selectGradeEvent (item: any): void {
            this.resetAllData()
            if(this.selectObj.grade == item.name) {
                this.selectObj.grade = ''
                this.selectObj.gradeId = ''
                this.getSelectOptionsStable('')
                this.formatSubjectsList('')
            } else {
                this.selectObj.grade = item.name
                this.selectObj.gradeId = item.id
                this.getSelectOptionsStable(item.id)
                this.formatSubjectsList(item.id)
            }
            this.selectObj.area = ''
            this.selectObj.subject = []
            this.selectObj.restnum = ''
            this.selectObj.onlyclassid = ''
            this.restnumActive = ''
            // this.setStoreSelectObj()
        }
        // 选择校区
        private selectAreaEvent (item: string): void {
            this.resetAllData()
            if(this.selectObj.area != item && this.selectObj.area) {
            } else {
                if(this.selectObj.area == item) {
                    this.selectObj.area = ''
                } else {
                    this.selectObj.area = item
                }
                // this.setStoreSelectObj()
                this.getSelectOptionsStable(this.selectObj.gradeId)
            }
        }
        // 选择学科
        private selectSubjectEvent (item: any): void {
            if(item.canSelectFlag) {
                if(this.selectObj.subject.includes(item.text)) {
                    this.selectObj.subject = this.selectObj.subject.filter((v: string): boolean => v != item.text)
                } else {
                    this.selectObj.subject.push(item.text)
                }
                // this.setStoreSelectObj()
            }
        }

        // 选择剩余名额
        private selectRestnumEvent (item: any, idx: number): void {
            if(idx > 1 && item.hasenter < item.stdnum) {
                if(this.selectObj.restnum == item.restnum) {
                    this.selectObj.restnum = ''
                } else {
                    this.selectObj.restnum = item.restnum
                }
                // this.setStoreSelectObj()
            }
        }

        // 设置存储在store里面的对象
        private setStoreSelectObj (): void {
            this.setSelectObj(this.selectObj)
        }
        // 删除所选内容
        private deleteSelectObj (attr: string, index?: number): void {
            console.log(attr, '。。。。。')
            if(attr != 'subject') {
                this.selectObj[attr] = ''
                if(attr == 'restnum') {
                    this.selectObj.onlyclassid = ''
                    this.restnumActive = ''
                }
                if (attr === 'grade') {
                    this.selectObj['grade'] = ''
                    this.selectObj['gradeId'] = '' 
                }
            } else {
                
                this.selectObj[attr] = this.selectObj[attr].filter((v: string, i: number): boolean => i != index)
            }
            console.log(this.selectObj, '返回select')
            // this.setStoreSelectObj()
              this.getGradeByBranchschool()
              this.getGrade()
        }
        // 删除所有所选内容
        private delAllEvent (): void {
          const that: any = this
          Object.keys(this.selectObj).forEach((item: any) => {
            if (item != 'subject') {
              that.selectObj[item] = ''
            }else {
              that.selectObj[item] = []
            }

          })
          this.getGradeByBranchschool()
          this.getGrade()
        }
        // 暂无数据，点击申请预约测试
        private applyTest () {
            const _this: any = this
            if (!this.testFlag) {
                const userInfo = localStorage.getItem('fangtian_personal_Data')
                let data = {}
                if (userInfo && (typeof userInfo === "string")) {
                    const user = JSON.parse(userInfo)
                    data = {
                        userId: user.id,
                        branchSchoolId: user.branch_school_id
                    }
                }
                this.testFlag = true
                _this.$confirm({
                        title: "",
                        subTitle: "Your application of [ appointment for test] has been sent successfully. The teacher will contact you as soon as possible! You can also take the initiative in contacting us. For more informa- tion, advise Tel: 97504680！",
                        width: "320px",
                        showClose: false,
                        btns: {
                            sure: "Got it",
                            cancel: "",
                        }
                })
                this.apply(data)

                
            }
        }
        private async apply(data: any): Promise<any> {
            // const res: any = await Caxios.get({ url: '/api/datadict/multiply/SUBJECT,GRADE,bookTest' })
            const res: any = await Caxios.post({ url: '/api/testappointment/add', data: data })
            console.log(res, '发送邮件')
        }
    }
