
import { Component, Vue, Watch } from "vue-property-decorator"
@Component({})
export default class SModal extends Vue {
  public visible = false

  private handleOk(): void {
    this.$emit('registSuc')
  }
  public open(): void {
    this.visible = true
  }
  private cancel(): void {
    this.visible = false
    this.$emit('registSuc')
  }
}
