var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"mine-curriculum"},[_c('div',{staticClass:"cur-course"},[(_vm.id == 0)?_c('div',{staticClass:"test-title"},[_vm._v("Test for admission")]):_vm._e(),(_vm.id != 0)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.curriculumObj.course_id),expression:"!curriculumObj.course_id"}],staticClass:"tac no-course-wrapper"},[_c('img',{attrs:{"src":require("@/assets/imgs/no_course.png"),"alt":""}}),_c('p',{staticClass:"nc-text"},[_vm._v("暂无课程")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.curriculumObj.course_id),expression:"curriculumObj.course_id"}]},[_c('p',{staticClass:"mb8px cc-title"},[_vm._v(_vm._s(_vm.curriculumObj.course_name))]),(
              _vm.selectObj.id &&
              _vm.selectObj.termtype &&
              _vm.selectObj.teachername &&
              _vm.selectObj.week &&
              _vm.selectObj.during &&
              _vm.selectObj.classlevel &&
              _vm.selectObj.areas
            )?_c('p',{staticClass:"mb8px cc-sub-title"},[_vm._v(" "+_vm._s(_vm.selectObj.classname || '-')+" ")]):_vm._e(),_c('p',{staticClass:"mb8px cc-body flex"},[_c('span',[_vm._v("Frequency：")]),_c('span',[_vm._v(_vm._s(_vm.frequencyObj[_vm.curriculumObj.frequency_name]))])]),_c('p',{staticClass:"mb8px cc-body flex"},[_c('span',[_vm._v("Sessions：")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.curriculumObj.total_lesson))])]),_c('p',{staticClass:"mb8px cc-body flex"},[_c('span',[_vm._v("Time：")]),_c('span',[_vm._v(_vm._s(_vm.curriculumObj.start_time)+"~"+_vm._s(_vm.curriculumObj.end_time))])]),(
              _vm.selectObj.id &&
              _vm.selectObj.termtype &&
              _vm.selectObj.teachername &&
              _vm.selectObj.week &&
              _vm.selectObj.during &&
              _vm.selectObj.classlevel &&
              _vm.selectObj.areas
            )?_c('p',{staticClass:"cc-body"},[_vm._v(" Classroom："+_vm._s(_vm.selectObj.schoolarea)+_vm._s(_vm.selectObj.classroom)+" ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"thin-width"}),_c('div',{staticClass:"flex ai-c jc-s mb8px sign-up-wrapper"},[_c('p',[_vm._v(" "+_vm._s((_vm.personInfo.moneySymbol || 'S$') + ' ' + (_vm.selectObj.totalprice ? _vm.selectObj.totalprice : 0))+" ")]),_c('p',{staticClass:"tac cur su-btn",class:{
            disabled:
              (!_vm.curriculumObj.course_id && _vm.id != 0) ||
              !_vm.canSignupFlag ||
              (_vm.id == 0 &&
                (!_vm.selectObj.grade ||
                  !_vm.selectObj.area ||
                  _vm.selectObj.subject.length == 0 ||
                  !_vm.selectObj.restnum)) ||
              (_vm.id != 0 &&
                (!_vm.selectObj.levels ||
                  !_vm.selectObj.areas ||
                  !_vm.selectObj.teachers ||
                  !_vm.selectObj.restnum)),
          },on:{"click":_vm.signUpEvent}},[_vm._v(" Register "),_c('a-spin',{directives:[{name:"show",rawName:"v-show",value:(_vm.lodingflag),expression:"lodingflag"}],attrs:{"size":"large"}})],1)]),_c('p',{staticClass:"info"},[_vm._v("Note: Please register for courses according to test levels")])]),(_vm.id != 0 && _vm.testFlag)?_c('div',{staticClass:"flex ai-c jc-s cur app-reg",on:{"click":_vm.entranceTestEvent}},[_c('img',{attrs:{"src":require("@/assets/imgs/yuyue.png"),"alt":""}}),_c('span',{staticClass:"left"},[_vm._v("Book a free diagnostic test!")]),_vm._m(0)]):_vm._e(),_c('div',{staticClass:"hot-course"},[_c('div',{staticClass:"pos-r hc-title"},[_vm._v("Popular Courses")]),_c('ul',{staticClass:"hc-body"},_vm._l((_vm.hotCourseList),function(item,index){return _c('li',{key:index,staticClass:"cur",on:{"click":function($event){return _vm.signUpEventNewItem(item)}}},[_c('div',{staticClass:"hcb-title"},[_vm._v(_vm._s(item.course_name))]),_c('div',{staticClass:"hcb-body"},[_vm._v(" "+_vm._s((_vm.personInfo.moneySymbol || 'S$') + ' ' + item.total_price))])])}),0)]),_c('s-modal',{ref:"modal",on:{"registSuc":_vm.handleOk}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"pos-r right"},[_c('i',{staticClass:"pos-a"})])
}]

export { render, staticRenderFns }